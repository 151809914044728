<template>
    <div>
        <b-row>
          <b-col class="col-md-6 col-12">
            <Breadcrumbs main="" title="Services" class="showBreadcrumbs"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="12" md="">
            <b-card title="" type="gradient-red" sub-title="" class="mb-4">

              <b-row class="align-items-start">
                <b-col class="col-md-6 col-12">
                  <span class="custome-title">Service Info</span>
                </b-col>
                <b-col class="col-md-6 col-12 d-flex justify-content-end align-items-start">
                  <b-form-group
                    label-cols="7"
                    label="Per page"
                    class="col-6 md-0 custom-font-18 text-right"
                    >
                    <b-form-select
                      class=" border custom-font-24"
                      v-model="perPage"
                      :options="pageOptions"
                      >
                    </b-form-select>
                  </b-form-group>
                  <button class="btn custom-btn" @click="addService()">
                    Add Service</button>
                </b-col>
              </b-row>

              <div class="col-md-12" v-if="loading">
                    <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
                </div> 
              <div v-else  class="table-responsive datatable-vue">
              <b-table 
                class="text-left custom-font-16"
                :fields="serviceFields" 
                striped hover 
                :items="serviceData" 
                stacked="md"
                :filter="filter"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                >
                <template #cell(_id)="row">
                  <span class="cust_text">{{ row.item._id }}</span>
                </template>
                <template #cell(title)="row">
                  <span>{{ row.item.title }}</span>
                </template>
                <template #cell(status)="row">
                    <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=row.item.status @change="changeStatus(row.item)">
                        <span class="switch-state" :class="row.item.status?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>
                </template>
  
                <template #cell(spid)="row">
                    <span class="cust_text" v-if="providerlist.find((e) => e._id == row.item.spid) ">
                  {{ providerlist.find((e) => e._id == row.item.spid).name }}
                </span>
                </template>
                <template #cell(adtype)="row">
                    <span v-if="adTypeArray.find((e) => e.id == row.item.adtype)">
                        {{ adTypeArray.find((e) => e.id == row.item.adtype).name }}
                    </span>
                </template>
                <template #cell(category)="row">
                    <span v-if="categoryArray.find((e) => e.id == row.item.category)">
                        {{ categoryArray.find((e) => e.id == row.item.category).name }}
                    </span>
                </template>
                <!-- <template #cell(adUrl)="row">
                    <span class="cust_url text-blue" @click="CopyShareUrl(row.item.adUrl)">{{ row.item.adUrl }}</span>
                </template> -->
  
                <template #cell(actions)="row">
                  <button
                    size="sm"
                    @click="openSDetail(row.item)"
                    class="btn text-blue btn-xs mr-1"
                  >
                  <feather type="edit"></feather>
                  </button>
                  <button
                    size="sm"
                    @click="deleteSDetail(row.item)"
                    class="btn text-danger btn-xs"
                  >
                  <feather type="trash-2"></feather>
                  </button>
                </template>
              </b-table>
              </div>
              <b-col class="mt-3 p-0">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0 custom-font-16"
              ></b-pagination>
              </b-col>
            </b-card>
          </b-col>
        </b-row>

        <!-- Service provider Modal -->
        <b-modal v-model="serviceModal" id="openSPDetail" title="Service Provider" class="theme-modal" @hidden="closeModal" size="xl" no-close-on-backdrop hide-footer>
          <serdetail :sddetail="sddetail" :serviceModal="serviceModal" :isEdit="isEdit" @closeSdDetail="closeModal"></serdetail>
          <!-- <template #modal-footer>
                <div>
                  <b-button class="float-right mr-2" @click="closeModal()">
                    Close
                  </b-button>
                </div>
              </template> -->
        </b-modal>
  
      <b-container fluid class="mt--7">
        <b-row class="justify-content-center">
          <b-col lg="12"> </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  <script>
  import ServiceInfoService from "../../../../services/ServiceInfo";
  import ServiceProviderServices from "../../../../services/ServiceProviderServices";
  import serdetail from "./AddServiceInfo.vue";
  import config from "../../../../services/globalLit";
  
  export default {
    components: {
      serdetail,
    },
    mounted() {
      this.userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
      // if (this.userinfo.role != "manager") {
      //   this.$router.push("/auth/login");
      // }
      this.init();
    },
    data() {
      return {
        serviceModal: false,
        adTypeArray: config.adTypeArray,
        categoryArray: config.categorylist,
        providerlist: [],
        serviceFields: [
        {
          key: "_id",
          "label": "ID",
          sortable: true,
        },
          {
            key: "title",
            label: "Title",
            sortable: true,
          },
          {
            key: "spid",
            label: "Provider",
            sortable: true,
          },
  
          {
            key: "adtype",
            label: "Ad Type",
          },
          {
            key: "category",
            label: "Category",
            sortable: true,
          },
          // {
          //   key: "adUrl",
          //   label: "Ad Url",
          // },
          {
            key: "status",
            label: "Status",
          },
          {
            key: "actions",
            label: "Action",
          },
        ],
        serviceData: [],
        userinfo:{},
        sddetail:{},
        isEdit: false,
        loading: false,
        totalRows: 0,
        filter: null,
        currentPage: 1,
        perPage: 20,
        pageOptions: [20, 50, 100, 200],
        sortBy: "_id",
        sortDesc: true,
        sortDirection: "desc",
      };
    },
  
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      CopyShareUrl(urls){
        config.CopyShareUrl(urls)
        this.$toasted.show("Copied", {theme: 'outline',position: "bottom-center", type: "success", duration: 2000});
      },
      closeModal() {
        this.serviceModal = false;
        this.init();
        this.isEdit = false;
        },
      openSDetail(data) {
        this.isEdit = true;
        this.sddetail = data;
        this.serviceModal = true;
      },
      addService() {
        this.isEdit = false;
        this.sddetail = {};
        this.serviceModal = true;
      },
      async deleteSDetail(data) {
        let payload = {
          sid: data._id,
        };
        if (confirm("Are you sure you want to delete")) {
          let response = await ServiceInfoService.remove(payload);
          this.init();
          let variant = response.result ? "success" : "error";
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000});
          
        }
      },
      async changeStatus(data) {
        if (data.status == false) {
          data.status = true;
        } else {
          data.status = false;
        }
        let payload = {
          sid: data._id,
          changeBody: {
            status: data.status,
          },
        };
        let response = await ServiceInfoService.update(payload);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000});
      },
      async init() {
        this.loading = true;
  
        let [response, spData] = await Promise.all([
          ServiceInfoService.get(),
          ServiceProviderServices.get(),
        ]);
  
        if (spData.result) {
          this.providerlist = spData.data;
        }
        if (response.result) {
          this.serviceData = response.data.reverse();
          this.totalRows = this.serviceData.length;
        }
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.showBreadcrumbs {
  display: none !important;
}

@media screen and (max-width: 991px) {
  .showBreadcrumbs {
    display: block !important;
  }
}

@media screen and (max-width: 767px) {
  .custom-font-18,
  .custom-font-24 {
    font-size: 16px !important;
  }
}
</style>
  